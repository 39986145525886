<template>
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
            <div class="position-relative d-flex align-items-center">
                <icon-plus :size="15" class="position-absolute" style="left: 10px;" v-if="!loadingCode" :color="'var(--gray-1001)'"/>
                <half-circle-spinner :animation-duration="1000" :size="15" color="var(--blue-600)" class="position-absolute" style="left: 10px;" v-else/>
                <input type="number" class="input-code inputs-search form-control me-3" :id="`input-code-${quotationID.toLowerCase()}`" 
                placeholder="Agregar Producto (Código)" @keyup.enter="addProductToQuotation" v-model="code" :disabled="loadingProduct">
            </div>     
            <div class="position-relative d-flex align-items-center">
                <icon-search :size="15" class="position-absolute" style="left: 7px;" :color="'var(--gray-1001)'" v-if="!loadingProduct"/>
                <half-circle-spinner :animation-duration="1000" :size="15" color="var(--blue-600)" class="position-absolute" style="left: 10px;" v-else/>
                <input type="text" class="input-code inputs-search form-control" :id="`input-search-by-description`" 
                @keyup.enter="searchProduct" :disabled="loadingProduct" v-model="searchByDescription" placeholder="Buscar productos">
            </div>     
        </div>
        <div class="d-flex">
            <second-button :title="'account.cancel'" style="width: 120px;height: 35px;" :disabled="!isChanged(quotationID)" class="me-2" @click="cancel"/>
            <main-button :title="saving ? '' : 'account.save'" :disabled="saving || !isChanged(quotationID)" 
            style="width: 120px;height: 35px;" @click="updateQuotation" :icon="saving">
                <div class="p-1">
                    <half-circle-spinner :animation-duration="1000" :size="22" color="#fff" v-if="saving"/>
                </div>
            </main-button>
        </div>
        <search-product v-if="showSearchProduct" @closeModal="showSearchProduct = false" :quotation="quotation" 
        :searchedProducts="searchedProducts" :search="searchByDescription"/>
        <confirm-cancel v-if="showModalCancel" @confirm="cancelUpdate" @closeModal="showModalCancel = false"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { HalfCircleSpinner } from 'epic-spinners'
    import MainButton from '../../../../../common/components/buttons/MainButton.vue'
    import SecondButton from '../../../../../common/components/buttons/SecondButton.vue'
    import iconPlus from '../../../../../common/svg/iconPlus.vue'
    import IconSearch from '../../../../../common/svg/iconSearch.vue'

    import {ProductService} from "../../../../products/services/product-service.js";
    import SearchProduct from '../modals/SearchProduct.vue'
    import ConfirmCancel from '../modals/ConfirmCancel.vue'
    const productService = new ProductService();
    export default {
        components: { iconPlus, IconSearch, SecondButton, MainButton, HalfCircleSpinner, SearchProduct, ConfirmCancel },
        name: 'ActionGroup',
        props:{
            quotation:{
                type: Object
            },
            products:{
                type: Array
            },
            quotationID:{
                type: String
            }
        },
        computed:{
            ...mapGetters({
                changedQuotes: "account/changedQuotes",
            })
        },
        data(){
            return{
                loadingCode: false,
                loadingProduct: false,
                saving: false,
                showSearchProduct: false,
                showModalCancel: false,

                searchByDescription: '',
                code: '',

                searchedProducts: {
                    totalDocs: 0,
                    totalPages: 0,
                    products: []
                },
            }
        },
        methods:{
            isChanged(id){
                let index = this.changedQuotes.findIndex(item=> item.quotation == id)
                return index != -1
            },
            // Agregar -> AGREGAR PRODUCTO
            async addProductToQuotation(){
                try {
                    if(!this.code) return
                    const product = this.products.find(product => product.product.codarticulo == parseInt(this.code))
                    if(product) {
                        this.$store.commit('common/SHOW_MODAL_ERROR', {
                            show: true, 
                            title: `Producto previamente agregado`, 
                            contentHTML: `
                            <p class='size-14 text-center'>
                                Este producto ya fue previamente agregado en <a>${this.quotationID}</a>.
                                <br>
                                <span>(<span class="color-main-red weight-600">${product.product.codarticulo}</span>)</span>
                                <a>${this.$options.filters.textFormat(product.product.description)}</a>.
                            </p>`
                        })
                        this.code = ""
                        return
                    }
                    this.loadingCode = true
                    const response = await productService.getProduct(this.code, this.quotation.branch_code);
                    if(response.length == 0){
                        this.$store.commit('common/SHOW_MODAL_ERROR', {
                            show: true, 
                            title: `Ocurrio un error obteniendo los datos.`, 
                            description: `Producto (${this.code}) no existe. Por favor, verificar si el codigo es correcto.`
                        })
                        return
                    }
                    this.$store.commit('account/ADD_PRODUCT_TO_QUOTATION', {id: this.quotationID, ...response})
                    // this.$emit('changed')
                    // this.$store.commit('account/CHANGED_QUOTATION', {quotation: this.quotationID, codarticulo: response.product.codarticulo})
                    let table = document.getElementById(`table-product-${this.quotationID}`)
                    // const y = table.getBoundingClientRect().top + window.scrollY;
                    this.code = ''
                    setTimeout(() => {
                        let input = document.getElementById(`input-quantity-${this.quotationID.toLowerCase()}-${response.product.codarticulo}`)
                        input.focus()
                        table.scroll({top: table.scrollHeight, behavior: 'smooth'});
                    }, 100);
                } catch (error) {
                    console.log(error)
                    this.$store.commit('common/SHOW_MODAL_ERROR', {
                        show: true, 
                        title: `Ocurrio un error obteniendo los datos.`, 
                        description: error.data.message
                    })
                } finally{
                    this.loadingCode = false
                }
            },
            async searchProduct(){
                let params = { limit: 50, place: this.quotation.branch_code, page: 1, q: this.searchByDescription, category: this.category};
                try {
                    this.loadingProduct = true
                    const products = await productService.getProducts({...params});
                    this.searchedProducts = {
                        totalDocs: products.totalDocs,
                        totalPages: products.totalPages,
                        products: products.docs
                    }
                    this.showSearchProduct = true
                    
                } catch (error) {
                    console.log(error)
                } finally {
                    this.loadingProduct = false
                }
            },
            async searchProducts(data){
                this.searchByDescription = data.description ?? data
                await this.searchProduct()
            },
            async changeCategory(category){
                this.category = category.value
                await this.searchProduct()
            },
            getProducts(products){
                products.forEach(product => {
                    this.searchedProducts.products.push(product)
                });
            },
            cancel(){
                this.showModalCancel = true
            },
            async cancelUpdate(){
                try {
                    await this.$store.dispatch('account/getQuotationByID', {id: this.quotationID, refresh: true})
                    await this.$store.dispatch('cart/clearCart');
                    this.$store.commit('account/REMOVE_FROM_CHANGED_QUOTATION', this.quotationID)
                    this.$store.commit('account/SELECT_QUOTATION', null)
                    this.showModalCancel = false
                } catch (error) {
                    console.log(error)
                }
            },
            async updateQuotation(){
                try {
                    this.saving = true
                    // const quotation = this.quotes.find(item => item.order_token_id == id)
                    const  productsWithoutQuantity= this.quotation.order_details.filter(product => !product.quantity)
                    
                    if(productsWithoutQuantity.length > 0){
                        let errorData = {
                            show: true,
                            title: `Digite la cantidad.`,
                            contentHTML: 
                            `<div class="text-start color-dark-gray-300">
                                <span class="mb-2">No ha digitado cantidad en el producto:</span> 
                                <br>
                                <ul class="list-group size-14">
                                    ${productsWithoutQuantity.map(item=> `<li class="list-group-item"><a>${item.product.codarticulo} - ${this.$options.filters.textFormat(item.product.description)}</a></li>`).join('')}
                                </ul>
                            </div>`
                        }
                        this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
                        return
                    }
                    await this.$store.dispatch('account/updateQuotation', this.quotationID)

                    let notify = {
                        type: 'success',
                        show: true,
                        contentHTML: `
                            <span class="size-14">
                                La cotizacion <a>${this.quotationID}</a> a sido actualizada con exito.
                            </span>
                        `
                    }
                    this.$store.commit("common/SET_SHORT_NOTIFY", notify);

                } catch (error) {
                    console.log(error)
                    let errorData = {
                        show: true,
                        title: `Ocurrio un error actualizando la cotizacion.`,
                        contentHTML: 
                        `<div class="text-center color-dark-gray-300">
                            ${error.response.data.message}
                        </div>`
                    }
                    this.$store.commit('common/SHOW_MODAL_ERROR', errorData)
                } finally{
                    this.saving = false
                }
            },
        }
    }
</script>

<style scoped>
    .inputs-search{
        border-color: var(--gray-50) !important;
        border-radius: 4px;
        height: 35px;
        border-radius: 7px;
        text-align: start;
        padding-left: 35px;
        min-width: 240px;
        font-size: 14px;
    }
    .inputs-search::placeholder{
        color: var(--dark-gray-200);
    }
</style>