<template>
    <card class="py-3 px-4">
        <div class="row">
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Total</span>
                <h4>RD$ 85,548.00</h4>
            </div>
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Total de cotizaciones</span>
                <h4>20</h4>
            </div>
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Ultimo mes</span>
                <h4>05</h4>
            </div>
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Ultima cotizacion</span>
                <h4>29/03/2022</h4>
            </div>
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Cotizaciones facturadas</span>
                <h4>305</h4>
            </div>
            <div class="col-md-2">
                <span class="color-dark-gray-200 size-14">Ultima Facturada</span>
                <h4>29/03/2022</h4>
            </div>
        </div>
    </card>
</template>

<script>
import Card from '../../../../common/components/cards/Card.vue'
    export default {
    components: { Card },
        name: 'QuotationSummary'
    }
</script>

<style scoped>
    .card-container{
        border-radius: 10px;
    }
</style>