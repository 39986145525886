<template>
    <div class="table-responsive pe-2" id="modal-product-table">
        <table class="w-100 quotes-table">
            <thead>
                <th></th>
                <th class="py-1">{{$t('account.code')}}</th>
                <th>{{$t('account.description')}}</th>
                <th>{{$t('product.existence')}}</th>
                <th>{{$t('account.quantity')}}</th>
                <th>{{$t('product.price')}}</th>
                <th>{{$t('account.amount')}}</th>
            </thead>
            <tbody class="size-14" v-if="!loading && products.length > 0 || loadingWithScroll">
                <tr v-for="product in products" :key="`item-${product.codarticulo}`" style="border-bottom: 1px solid var(--light-gray-100);">
                    <td style="width: 1%;" class="py-2">
                        <image-product :source="getImages(product.imageGallery)" :styleObject="{width: '50px', height: '50px'}" class="border rounded me-2" @click.native="quickView={show: true, product: product}"/>
                    </td>
                    <td style="width: 10%;">({{product.codarticulo}})</td>
                    <td class="text-start" style="width: 40%;"><h6 class="mb-0 size-14 hover-main-red" @click="quickView={show: true, product: product}">{{product.description | textFormat}}</h6></td>
                    <td :class="{'color-main-red':!product.existence}">{{product.existence}}</td>
                    <td>
                        <input type="number" class="input-code form-control m-auto" :id="`input-quantity-${product.codarticulo}`" 
                        @change.stop="updateQuantity($event, product)" :value="getQuantity(product.codarticulo, product.quantity)" >
                    </td>
                    <td>{{product.price | moneyFormat}}</td>
                    <td class="weight-600" style="width: 135px;">{{product.price | moneyFormatMultiply(product.quantity)}}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-if="loading">
                    <td colspan="7">
                        <span class="color-blue-600 size-16">{{$t('Buscando')}}...</span>
                        <half-circle-spinner :animation-duration="1000" :size="20" color="var(--blue-600)" class="m-auto mt-2"/>
                    </td>
                </tr>
                <tr v-else>
                    <td colspan="7">
                        <without-results v-if="!products.length && !loading" @click="$emit('resetFilters')" class="mt-5" 
                        description="account.no_item_matches" title="product.no_product_was_found" titleButton="product.reset_search">
                            <template #main-icon>
                                <icon-no-product :size="90"/>
                            </template>
                            <template #button-icon>
                                <icon-change class="fill-white me-2" :size="17"/>
                            </template>
                        </without-results>
                    </td>
                </tr>
            </tbody>
            <tfoot class="p-2">
                <tr>
                    <td colspan="4">
                        <div class="d-flex px-2 align-items-center" v-if="loadingWithScroll">
                            <span class="color-blue-600 size-16">{{$t('product.loading_more')}}</span>
                            <half-circle-spinner :animation-duration="1000" :size="20" color="var(--blue-600)" class="ms-2"/>
                        </div>
                    </td>
                    <th></th>
                    <th scope="row" class="size-16 py-2">Total (Peso)</th>
                    <th class="size-16"><span class="color-main-red">{{showValueMoney()}}$</span> {{parseFloat(amount).toFixed(2)}}</th>
                </tr>
            </tfoot>
            <quick-view :product="quickView.product" :checkout="true" v-if="quickView.show" @closeModal="quickView.show = false"/>
        </table>
    </div>
</template>

<script>
    import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";
    import ImageProduct from '../../../products/components/Image.vue'
    import QuickView from '../../../products/components/quick-view/QuickView.vue';
    import { HalfCircleSpinner } from 'epic-spinners'
    import WithoutResults from '../../../../common/components/modals/WithoutResults.vue';
    import IconNoProduct from '../../../products/assets/svg/iconNoProduct.vue';
    import IconChange from '../../../products/assets/svg/iconChange.vue';
    export default {
        components: { ImageProduct, QuickView, HalfCircleSpinner, WithoutResults, IconNoProduct, IconChange },
        name:'QuotationProductTable',
        props:{
            products:{
                type: Array
            },
            quotation:{
                type: Object
            },
            totalPages:{
                type: Number
            },
            amount:{
                type: Number
            },
            page:{
                type: Number
            },
            loading:{
                type: Boolean
            },
            loadingWithScroll:{
                type: Boolean
            },
            changedProducts:{
                type: Array
            }
        },
        mixins: [productMixin],
        data(){
            return{
                quickView: {product: null, show: false},
                finish: false,
                totalDocs: 0
            }
        },
        methods:{
            updateQuantity($event, product){
                if(parseInt($event.target.value) > parseInt(product.existence)){
                        let error = {
                            show: true,
                            title: `Cantidad Insuficiente.`,
                            contentHTML: 
                            `<div class="text-center color-dark-gray-300 size-15" style="line-height: 17px;">
                                <span class="mb-2">El producto <a>${product.description}</a> solo <br> cuenta con 
                                    <b>${product.existence}</b> en el sistema.
                                </span> 
                            </div>`
                        }
                        this.$store.commit('common/SHOW_MODAL_ERROR', error)
                        $event.target.value = 0
                    return
                }
                const find = this.products.find(item=> item.codarticulo == product.codarticulo)
                find.quantity = $event.target.value
                this.$emit('updateQuantity', find)
            },
            async handleScroll() {
                    const element = document.getElementById('modal-product-table');
                    if ((element.scrollTop + element.offsetHeight + 10) >= element.scrollHeight ){
                        if(!this.loading && (this.page <= this.totalPages)){
                            this.$emit('nextPage', this.page + 1)
                        }
                    }
            },
            getQuantity(codarticulo, quantity){
                const changedProduct = this.changedProducts.find(product => product.codarticulo == codarticulo)

                if(changedProduct)
                return changedProduct.quantity;

                const product = this.quotation.order_details.find(product => product.product.codarticulo == codarticulo)
                return product?.quantity || quantity;
            }
        },
        mounted(){
            const table = document.getElementById('modal-product-table')
            table.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy(){
            // const table = document.getElementById('modal-product-table')
            // console.log(table)
            // table.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style scoped>
    .quotes-table{
        text-align: center;
    }
    .quotes-table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .quotes-table > tfoot {
        position: sticky;
        bottom: 0;
        z-index: 1;
        background-color: var(--light-gray-100);
    }
    .quotes-table > thead > tr > th {
        font-weight: 400;
        font-size: 14px;
    }
    .input-code{
        max-width: 60px;
        border-color: var(--gray-100) !important;
        border-radius: 4px;
        border: 1px solid;
        padding: 1px 0px;
        font-size: 14px;
        text-align: center;
    }
    .option-tooltip{
        border-radius: 6px;
        display: none;
    }
    .btn-action{
        height: 30px;
        width: 30px;
    }
    .btn-action:hover > .option-tooltip, 
    .order-token-id:hover > .option-tooltip{
        display: block;
    }

    textarea:focus-visible{
        outline: none;
    }
    textarea {
        border-color: #cedde9 !important;
        border-radius: 7px !important;
        font-size: 14px;
    }
    textarea::placeholder{
        color: var(--dark-gray-200);
        font-weight: 100;
        font-size: 14px;
    }
    .popup-add-note.popup::v-deep::before{
        right: 28px;
        top: 0px;
        transform: translate(50%, -50%) rotate(316deg);
        width: 20px;
        height: 20px;
    }
    .image__wrapper{
        width: 60px;
        height: 60px;
    }
    #modal-product-table {
        max-height: 60vh;
    }
    @media (min-height: 600px) {
        #modal-product-table{
            height: 50vh;
        }
    }
</style>