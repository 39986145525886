<template>
    <main-modal @closeModal="$emit('closeModal')" :title="''">
        <div class="d-flex align-items-center justify-content-between my-3">
            <div class="text-start">
                <h5 class="weight-600 mb-0">{{$t('Buscar Productos')}}</h5>
                <span class="subtitle-14 color-dark-gray-300 weight-300">
                    {{$t("product.showing")}} {{docs.products.length}} {{$t('product.of')}} {{docs.totalDocs}} {{$t("product.results")}}
                </span>
            </div>
            <header-search :isNav="false" @setSearch="searchProducts" @changeCategory="changeCategory" :search="search"/>
        </div>
        <modal-product-table :products="docs.products" @nextPage="nextPage" :quotation="quotation" class="mt-3" :totalPages="docs.totalPages" 
        :page="filters.page" :amount="amount" :loading="loading" @updateQuantity="updateQuantity" :loadingWithScroll="loadingWithScroll" :changedProducts="products"
        @resetFilters="resetFilters"/>
        <div class="mt-4 d-flex justify-content-center align-items-center">
            <second-button :title="'account.cancel'" style="width: 120px;height: 35px;" class="me-2" @click="$emit('closeModal')" :disabled="products.length == 0"/>
            <main-button :title="'account.save'" style="width: 120px;height: 35px;" @click="updateQuotation" :disabled="products.length == 0"/>
        </div>
    </main-modal>
</template>

<script>
    import MainButton from '../../../../../common/components/buttons/MainButton.vue'
    import SecondButton from '../../../../../common/components/buttons/SecondButton.vue'
    import MainModal from '../../../../../common/components/modals/MainModal.vue'
    import ModalProductTable from '../ModalProductTable.vue'
    import HeaderSearch from '../../../../layout/components/navbar/search/HeaderSearch.vue'
    import {ProductService} from "../../../../products/services/product-service.js";
    const productService = new ProductService();
    export default {
        components: { MainModal, ModalProductTable, SecondButton, MainButton, HeaderSearch },
        name: 'SearchProduct',
        props:{
            quotation:{
                type: Object
            },
            searchedProducts:{
                type: Object
            },
            search:{
                type: String
            },
        },
        data(){
            return{
                limit: 50,
                loading: false,
                loadingWithScroll: false,
                docs: this.searchedProducts,
                filters: {
                    category: 0,
                    search: this.search,
                    page: 1,
                },
                products: []
            }
        },
        computed:{
            amount(){
                let total = 0
                this.products.forEach(product => {
                    total += product.quantity * product.price
                });

                return total
            }
        },
        watch:{
            filters:{
                deep: true,
                async handler(){
                    if(!this.loading)
                    await this.getProducts()
                }
            }
        },
        methods:{
            async getProducts(){
                let params = { limit: 50, place: this.quotation.branch_code, page: this.filters.page, q: this.filters.search, category: this.filters.category};
                try {
                    this.loading = true
                    this.$emit('changeLoading', true)
                    if(this.filters.page > this.totalPages) return

                    const response = await productService.getProducts(params);
                    response.docs.forEach(product => {
                        this.docs.products.push(product)
                    });
                    this.docs.totalDocs = response.totalDocs                    
                    this.$emit('changePage', this.filters.page)
                }catch (e) {
                    this.error = {
                        show: true,
                        title: 'common.connection_error',
                        description: 'common.notification.unexpected_error_product'
                    };
                    this.$store.commit('common/SHOW_MODAL_ERROR', this.error);
                } finally {
                    this.loading = false
                    this.loadingWithScroll = false
                    this.$emit('changeLoading', false)
                }
            },
            updateQuotation(){
                this.$store.commit('account/ADD_PRODUCTS_TO_QUOTATION', {id: this.quotation.order_token_id, products: this.products})
                this.$emit('closeModal')
            },
            updateQuantity(product){
                this.products.push(product)
            },
            async searchProducts(search){
                // console.log(search, this.docs.products)
                this.docs.products = []
                // console.log(this.docs.products)
                this.filters.page = 1
                this.filters.search = search.description ?? search
                // if(!this.loading)
                // await this.getProducts()
            },
            async changeCategory(category){
                this.docs.products = []
                this.filters.page = 1
                this.filters.category = category.value
                await this.getProducts()
            },
            async nextPage(page){
                this.loadingWithScroll = true
                this.filters.page = page
                // await this.getProducts()
            },
            resetFilters(){
                this.filters = {
                    category: 0,
                    search: '',
                    page: 1,
                }
            }
        },
    }
</script>

<style scoped>
    .modal-main-container::v-deep > .modal-main {
        max-width: 90%;
    }
    .header-search::v-deep > form > .input-group{
        justify-content: end;
    }
    .header-search::v-deep > form > .input-group > input{
        border: 1px solid var(--gray-100);
        max-width: 400px;
    }
    @media (min-width: 1200px) {
        .modal-main-container::v-deep > .modal-main {
            width: 1100px;
        }
    }
</style>