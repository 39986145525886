<template>
    <table class="w-100 quotes-table">
        <thead>
            <th>
                <icon-arrow :size="12" class="btn-collapse cursor-pointer" :color="'var(--blue-600)'" :class="{'icon-active': resize}" @click.native="$emit('resize')"/>
            </th>
            <th class="py-1">{{$t('account.code')}}</th>
            <th>{{$t('account.description')}}</th>
            <th>{{$t('product.existence')}}</th>
            <th>{{$t('account.quantity')}}</th>
            <th>{{$t('product.price')}}</th>
            <th>{{$t('account.subtotal')}}</th>
            <th>{{$t('account.tax')}}</th>
            <th>{{$t('account.amount')}}</th>
            <th v-if="currentState.id == 3">{{$t('account.actions')}}</th>
        </thead>
        <tbody class="size-14" v-if="products.length > 0">
            <tr v-for="{product, amount, amount_sub, amount_tax, quantity, price_sold, note} in products" :key="`item-${product.codarticulo}`" 
                :class="[isProductChanged(product.codarticulo,quotationID) ? isProductChanged(product.codarticulo,quotationID).delete ? 'product-deleted' : 'product-changed' : 'body-tr-product']">
                <td style="width: 1%;" class="p-2" id="td-product-image">
                    <image-product :source="getImages(product.imageGallery)" :styleObject="{width: '60px', height: '60px'}" class="border rounded me-2" @click.native="quickView={show: true, product: product}"/>
                </td>
                <td style="width: 6%;" id="td-product-codarticulo">({{product.codarticulo}})</td>
                <td class="text-start" style="width: 23%;" id="td-product-description"><h6 class="mb-0 size-14">{{product.description | textFormat}}</h6></td>
                <td :class="{'color-main-red' : product.existence < quantity}">{{product.existence}}</td>
                <td id="td-product-quantity">
                    <input type="number" class="input-code form-control m-auto" :class="{'border-0 bg-transparent': currentState.id != 3 || product.type_id == 4}" :disabled="currentState.id != 3 || product.type_id == 4" 
                    :id="`input-quantity-${quotationID.toLowerCase()}-${product.codarticulo}`" @change.stop="updateQuantityQuotation($event, {id: quotationID, product: product})" :value="parseInt(quantity)" >
                </td>
                <td>{{ currency.simbol }} <span>{{price_sold |  moneyFormat(0, false)}}</span></td>
                <td>{{ currency.simbol }} <span>{{amount_sub |  moneyFormat(0, false)}}</span></td>
                <td>{{ currency.simbol }} <span>{{amount_tax |  moneyFormat(0, false)}}</span></td>
                <td class="weight-600" id="td-product-amount">{{ currency.simbol }} <span>{{amount |  moneyFormat(0, false)}}</span></td>
                <td v-if="currentState.id == 3" id="td-product-actions">
                    <div class="d-flex justify-content-center px-1" v-if="product.type_id != 4">
                        <button class="btn-action" @click="deleteProductFromQuotation(quotationID, product)" v-if="isProductChanged(product.codarticulo) ? !isProductChanged(product.codarticulo).delete : true">
                            <icon-trash :color="'var(--gray-1001)'" :size="25" class="hover-main-red"/>
                            <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-40px`, left: `-15px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                Eliminar
                            </popup>
                        </button>
                        <button class="btn-action" @click="restoreProductFromQuotation(product.codarticulo, quantity)" v-else>
                            <icon-refresh :color="'var(--gray-1001)'" :size="20" class="hover-main-red"/>
                            <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-40px`, left: `-12px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                Restaurar
                            </popup>
                        </button>
                        <button class="btn-action ms-2 justify-content-center" @click="showAddNote(product.codarticulo, note)">
                            <popup v-if="addNote.quotation == quotationID && addNote.product == product.codarticulo && addNote.active" 
                            :style="{top: `40px`, left: `-163px`, width: `330px`, padding: `20px`}" class="popup-add-note">
                                <h5 class="title-16 mb-0 weight-700 d-flex justify-content-between">
                                    {{$t('product.add_note')}} <icon-close :size="14" :color="'var(--dark-gray-200)'" @click.native.stop="closeNoteToProduct"/>
                                </h5>
                                <div class="d-flex justify-content-between size-14">
                                    <span class="color-dark-gray-300">{{note ? $t('cart.press_enter_to_save') : $t('cart.let_us_know_any_information')}}</span>
                                    <div>
                                        <span class="color-dark-gray-200 weight-100 me-2">150</span>
                                        <a href="#" v-if="note" @click.prevent="addNoteToProduct($event, {id: quotationID, codarticulo: product.codarticulo, clear: true})" class="weight-500 size-14">{{$t('cart.clear')}}</a>
                                    </div>
                                </div>
                                <textarea :id="`quotation-${quotationID}-product-note-${product.codarticulo}`" name="review" rows="3" cols="50" class="w-100 mt-2 px-3 py-1" maxlength="150" @keypress.enter="addNoteToProduct($event, {id: quotationID, codarticulo: product.codarticulo})"  
                                :placeholder="$t('product.report_textarea_placeholder')"/>
                            </popup>
                            <icon-comment color="var(--gray-1001)" :size="20" class="hover-main-red"/>
                            <popup class="option-tooltip no-wrap size-13 px-2 py-1" :style="{top: `-40px`, left: `-40px`, 'border-radius': `6px`}">
                                Agregar Nota
                            </popup>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="9">
                    <without-results :title="search ? 'Sin coincidencias de busqueda' : 'Producto Eliminados'" :description="search ? 'No encontramos ningun producto que coincida con la busqueda actual.' 
                    : 'Eliminaste todos los productos dentro de esta cotizacion.'" :titleButton="search ? 'Resetear busqueda' : 'Deshacer cambios'" class="mt-4 mb-4" @click="$emit('reset')">
                        <template #main-icon>
                            <icon-search-order v-if="search" :size="80"/>
                            <icon-delete-cart v-else :size="70"/>
                        </template>
                    </without-results>
                    <hr>
                </td>
            </tr>
        </tbody>
        <confirm-delete-product @confirmDelete="deleteConfirmed" @closeModal="showDeleteProductModal = false" :contentHTML="`
            <p class='size-14 text-center'>
                ¿ ${$t('account.sure_cancel_product')} <a>${selectedProduct.quotation}</a>?
                <br>
                <span>(<a>${selectedProduct.code}</a>)</span>
                <a>${selectedProduct.description}</a>
            </p>
        `" v-if="showDeleteProductModal"/>
        <quick-view :product="quickView.product" :checkout="true" v-if="quickView.show" @closeModal="quickView.show = false"/>
    </table>
</template>

<script>
    import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";
    import IconComment from '../../../products/assets/svg/iconComment.vue';
    import Popup from '../../../../common/components/popup/Popup.vue';
    import IconTrash from '../../../../common/svg/iconTrash.vue';
    import IconClose from '../../../../common/svg/iconClose.vue';
    import ImageProduct from '../../../products/components/Image.vue'
    import ConfirmDeleteProduct from '../../../products/components/modals/ConfirmDeleteProduct.vue';
    import QuickView from '../../../products/components/quick-view/QuickView.vue';
    import { mapGetters } from 'vuex';
    import IconArrow from '../../../../common/svg/iconArrow.vue';
    import WithoutResults from '../../../../common/components/modals/WithoutResults.vue';
    import IconDeleteCart from '../../../cart/assets/svg/iconDeleteCart.vue';
    import IconSearchOrder from '../../assets/svg/iconSearchOrder.vue';
    import IconRefresh from '../../../../common/svg/iconRefresh.vue';
    export default {
        components: { IconComment, Popup, IconTrash, ImageProduct, ConfirmDeleteProduct, IconClose, QuickView, IconArrow, WithoutResults, IconDeleteCart, IconSearchOrder, IconRefresh },
        name: 'QuotationProductTable',
        props: {
            products: {
                type: Array
            },
            currency: {
                type: Array
            },
            currentState: {
                type: Object
            },
            quotationID: {
                type: String
            },
            branchCode: {
                type: Number
            },
            orderDetails: {
                type: Array
            },
            resize: {
                type: Boolean
            },
            search: {
                type: String
            }
        },
        computed:{
            ...mapGetters({
                changedQuotes: "account/changedQuotes",
            })
        },
        mixins: [productMixin],
        data(){
            return{
                showDeleteProductModal: false,
                selectedProduct:{
                    code: null,
                    description: null,
                    quotation: null,
                },
                addNote: {
                    quotation: null,
                    product: null,
                    active: false
                },
                // loadingProduct: false,
                quickView: { show: false, product: null },
                // searchByDescription: '',
                productsSearch: [],
                showSearchProduct: false,
                totalDocs: null,
                totalPages: null,
                category: 0,
            }
        },
        methods: {
            // Seleccionar producto -> ELIMINAR PRODUCT  
            async deleteProductFromQuotation(id, product){
                this.selectedProduct = {
                    code: product.codarticulo,
                    description: product.description,
                    quotation: id
                }
                this.showDeleteProductModal = true
            },
            async restoreProductFromQuotation(codarticulo, quantity){
                // console.log(product)
                this.$store.commit('account/UPDATE_QUANTITY_PRODUCT_QUOTATION', {
                    id: this.quotationID, 
                    product: codarticulo, 
                    quantity: quantity
                })
            },
            // Confirmacion -> ELIMINAR PRODUCT  
            deleteConfirmed(){
                try {
                    this.$store.commit('account/DELETE_PRODUCT_FROM_QUOTATION', {id: this.selectedProduct.quotation, product: this.selectedProduct.code})
                    this.$emit('checkResult', this.selectedProduct.code)
                    this.changed = true
                    this.showDeleteProductModal = false
                } catch (error) {
                    console.log(error)
                }
            },
            // Actualizar -> CANTIDAD PRODUCTO
            updateQuantityQuotation(event, quotation){
                try {
                    if(parseInt(event.target.value) > parseInt(quotation.product.existence)){
                            let error = {
                                show: true,
                                title: `Cantidad Insuficiente.`,
                                contentHTML: 
                                `<div class="text-center color-dark-gray-300 size-15" style="line-height: 17px;">
                                    <span class="mb-2">El producto <a>${quotation.product.description}</a> solo <br> cuenta con 
                                        <b>${quotation.product.existence}</b> en el sistema.
                                    </span> 
                                </div>`
                            }
                            this.$store.commit('common/SHOW_MODAL_ERROR', error)
                            event.target.value = 0
                        return
                    }
                    this.$store.commit('account/UPDATE_QUANTITY_PRODUCT_QUOTATION', {
                        id: quotation.id, 
                        product: quotation.product.codarticulo, 
                        quantity: event.target.value
                    })
                    let input = document.getElementById(`input-code-${quotation.id.toLowerCase()}`)
                    input.focus()
                    this.$emit('changed')

                } catch (error) {
                    console.log(error)
                }
            },
            // Agregar nota -> ACTUALIZAR PRODUCTO
            showAddNote(codarticulo, note){
                this.addNote = { quotation: this.quotationID, product: codarticulo, active: true }
                setTimeout(() => {
                    let textarea = document.getElementById(`quotation-${this.quotationID}-product-note-${codarticulo}`)
                    textarea.value = note
                }, 0);
            },
            addNoteToProduct($event, quotation){
                if(!quotation.clear)
                this.addNote = {quotation: null, product: null, active: false}

                let value = quotation.clear ? '' : $event.target.value

                this.$store.commit('account/SET_NOTE_PRODUCT_QUOTATION', {
                    id: quotation.id, 
                    product: quotation.codarticulo, 
                    value: value
                })
            },
            closeNoteToProduct(){
                this.addNote = {quotation: null, product: null, active: false}
            },
            isChanged(id){
                let index = this.changedQuotes.findIndex(item=> item.quotation == id)
                return index != -1
            },
        }
    }
</script>

<style scoped>
    .quotes-table{
        text-align: center;
    }
    .quotes-table > thead {
        color: var(--dark-gray-300);
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 1;
    }
    .quotes-table > tfoot {
        background-color: #fff;
        position: sticky;
        bottom: 0;
        z-index: 1;
    }
    .quotes-table > thead > th {
        font-size: 14px;
        border-bottom: 1px solid var(--gray-100);

    }
    .input-code{
        max-width: 60px;
        border: 1px solid var(--gray-100);
        border-radius: 4px;
        padding: 1px 0px;
        font-size: 14px;
        text-align: center;
    }
    /* .inputs-search{
        border-color: var(--gray-100) !important;
        border-radius: 4px;
        height: 35px;
        border-radius: 7px;
        text-align: start;
        padding-left: 35px;
        min-width: 240px;
    } */
    .option-tooltip {
        border-radius: 6px;
        display: none;
    }
    .popup::v-deep::before {
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(134deg);
        width: 12px;
        height: 12px;
    }
    .btn-action{
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        position: relative;
    }
    .btn-action:hover > .option-tooltip, .order-token-id:hover > .option-tooltip{
        display: block;
    }

    textarea:focus-visible{
        outline: none;
    }
    textarea {
        border-color: #cedde9 !important;
        border-radius: 7px !important;
        font-size: 14px;
    }
    textarea::placeholder{
        color: var(--dark-gray-200);
        font-weight: 100;
        font-size: 14px;
    }
    .popup-add-note.popup::v-deep::before{
        right: 28px;
        top: 0px;
        transform: translate(50%, -50%) rotate(316deg);
        width: 20px;
        height: 20px;
    }
    .image__wrapper::v-deep{
        width: 60px;
        height: 60px;
        overflow: hidden;
    }
    .btn-collapse{
        transform: rotate(90deg);
        transition: var(--transition-1);
    }
    .icon-active{
        transform: rotate(-90deg) !important;
    }
    .product-changed{
        background-color: #fcf8e8bf;
        color: var(--warning-900);
    }
    .product-changed > #td-product-description > h6{
        color: var(--warning-900);
    }
    .product-changed > #td-product-quantity > input{
        color: var(--warning-900);
        border-color: var(--warning-900);
    }
    .product-changed > #td-product-actions > div > button > svg{
        fill: #8866198a;
    }
    .product-changed > #td-product-image > .image__wrapper::v-deep{
        border-color: #88661945 !important;
    }
    .product-deleted{
        background-color: #bc2c352b;
        color: var(--main-red);
    }
    .product-deleted > #td-product-description > h6{
        color: var(--main-red);
    }
    .product-deleted > #td-product-quantity > input{
        color: var(--main-red);
        border-color: var(--main-red);
    }
    .product-deleted > #td-product-actions > div > button > svg{
        fill: #bc2c35c7;
    }
    .product-deleted > #td-product-image > .image__wrapper::v-deep{
        border-color: #bc2c35c7 !important;
    }
</style>