<template>
        <div>
            <alert-warning :show="changedQuotes.length > 0" :showCounter="false" :content="true" class="p-2" actionTitle="Deshacer" @handlerAction="resetChangedQuotations()">
                <span class="subtitle-14 color-warning-900 weight-500 size-14">Cambios sin guardar en
                    <a href="#" v-for="item in changedQuotes.map(quotation=> quotation.quotation)" :key="item" class="changed-quotes" @click.prevent="goToQuotationPosition(item, true)">
                        {{item}}<span class="separator-changed-quotes subtitle-14 color-warning-900 weight-500">,</span>
                    </a>.
                </span>
            </alert-warning>
            <div class="table-responsive">
                <table class="table quotes-table m-0 align-middle">
                    <thead class="text-center align-middle">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">#</th>
                            <th scope="col">{{$t('account.document')}}</th>
                            <th scope="col">{{$t('account.date')}}</th>
                            <th scope="col">{{$t('account.hour_time')}}</th>
                            <th scope="col">{{$t('account.company_name')}}</th>
                            <th scope="col">{{$t('account.customer')}}</th>
                            <th scope="col">{{$t('account.branch')}}</th>
                            <th scope="col">{{$t('account.state')}}</th>
                            <th scope="col">{{$t('account.currency')}}</th>
                            <th scope="col">{{$t('account.amount')}}</th>
                            <th scope="col">{{$t('account.actions')}}</th>
                        </tr>
                    </thead>
                    <tbody class="size-14 text-center">
                        <template v-for="(quotation, index) in quotes">
                            <tr :key="quotation.order_token_id" @dblclick="goToQuotationPosition(quotation.order_token_id, false)" :id="`quotation-${quotation.order_token_id}`" 
                            :class="{'focus-quotation': (selectedId == quotation.order_token_id) || (quotationFocus == quotation.order_token_id) || (highlightedId === quotation.codfactura) || (highlightedId === quotation.order_token_id)}">
                                <td>
                                    <div class="p-2 rounded cursor-pointer" @click="goToQuotationPosition(quotation.order_token_id, false)">
                                        <icon-arrow :size="12" class="btn-collapse" :class="{'icon-active': selectedId == quotation.order_token_id}"/>
                                    </div>
                                </td>
                                <td>{{index + 1}}</td>
                                <td>
                                    <h6 class="size-14 mb-1 position-relative d-flex align-items-center justify-content-center order-token-id" 
                                    :class="[{'color-main-red': selectedQuotation == quotation.order_token_id}, {'color-blue-600': isChanged(quotation.order_token_id)}]">
                                        <icon-check-circle :size="14" class="me-2" v-if="selectedQuotation == quotation.order_token_id"/> 
                                        <icon-warning :size="14" class="me-2 hithere" v-if="isChanged(quotation.order_token_id)"/>
                                        {{quotation.codfactura ? quotation.codfactura : quotation.order_token_id}}
                                        <popup :style="{top: '-34px', left: '-17px'}" class="text-nowrap px-2 py-1 color-main-red option-tooltip" v-if="isChanged(quotation.order_token_id)">
                                            Cambias sin guardar
                                        </popup>
                                        <popup :style="{top: '-34px', left: '-17px'}" class="text-nowrap px-2 py-1 color-main-red option-tooltip" v-if="selectedQuotation == quotation.order_token_id">
                                            Cotizacion en carrito
                                        </popup>
                                    </h6>
                                    <span class="color-dark-gray-200">{{quotation.number_of_items}} {{$t('account.product') }}</span>
                                </td>
                                <td>
                                    <h6 class="size-14 mb-1">{{moment(quotation.creation).lang($i18n.locale).fromNow(true) | textFormat}}</h6>
                                    <span class="color-dark-gray-200">{{ $d(new Date(quotation.creation), 'alt_short')  | textFormat}}</span>
                                </td>
                                <td>
                                    <span class="color-dark-gray-200">{{moment(quotation.creation).format('LTS')}}</span>
                                </td>
                                <td class="weight-600 color-dark-gray-400 text-capitalize text-start">{{getName(quotation.customer_name, 'company_name') | textFormat}}</td>
                                <td class="text-capitalize text-start" style="width: 17%">
                                    <span class="color-dark-gray-200 weight-800">(<span class="color-main-navy weight-500">{{quotation.customer_code}}</span>) </span>
                                    {{getName(quotation.customer_name, 'name') | textFormat}}
                                </td>
                                <td>{{quotation.place.description | textFormat}}</td>
                                <td>
                                    <div class="status-bg px-2 d-flex align-items-center" :class="getState(quotation.quote_status_id).value">
                                        <icon-circle :size="6" class="me-1"/>
                                        {{quotation.quote_status_id}}
                                    </div>
                                </td>
                                <td>{{quotation.currency.description}}</td>
                                <td class="color-main-red weight-600">{{ quotation.currency.simbol }} <span class="color-main-red weight-600" :class="{'amount-changed': isChanged(quotation.order_token_id)}">{{quotation.amount |  moneyFormat(0, false)}}</span> </td>
                                <td>
                                    <div class="d-flex align-items-center justify-content-center px-2">
                                        <div v-if="selectedQuotation != quotation.order_token_id" class="d-flex align-items-center cursor-pointer position-relative btn-action" :class="{'btn-cart-disabled' : selectedQuotation || loading.quotation || getState(quotation.quote_status_id).id != 3}" @click="moveToCart(quotation)">
                                            <half-circle-spinner :animation-duration="1000" :size="20" color="var(--main-red)" v-if="loading.quotation == quotation.order_token_id && loading.active"/>
                                            <icon-cart :color="selectedQuotation || getState(quotation.quote_status_id).id != 3 ? 'var(--gray-100)' : 'var(--gray-1001)'" :size="22" class="hover-main-red" v-else/>
                                            <popup class="option-tooltip size-13 px-2 py-1"
                                            :style="{top: `-30px`, left: getState(quotation.quote_status_id).id != 3 ?  `-56px` : selectedQuotation ? `-95px` : `-42px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                                <span v-if="getState(quotation.quote_status_id).id != 3">Cotizacion <b>{{getState(quotation.quote_status_id).description}}</b></span>
                                                <span v-else-if="selectedQuotation">Cotizacion <a>{{selectedQuotation}}</a> en carrito</span>
                                                <span v-else>Agregar a carrito</span>
                                            </popup>
                                        </div>
                                        <div v-else class="d-flex align-items-center cursor-pointer position-relative btn-action" :class="{'btn-cart-disabled' : selectedQuotation != quotation.order_token_id || loading.quotation || getState(quotation.quote_status_id).id != 3}" @click.prevent="deleteProducts">
                                            <icon-close :color="selectedQuotation || getState(quotation.quote_status_id).id != 3 ? 'var(--gray-100)' : 'var(--gray-1001)'" :size="20" class="hover-main-red"/>
                                            <popup class="option-tooltip size-13 px-2 py-1"
                                            :style="{top: `-30px`, left: `-61px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                                {{$t("account.quit_quote")}}
                                            </popup>
                                        </div>
                                        <div class="d-flex ms-2 cursor-pointer position-relative btn-action" @click="print(quotation)">
                                            <icon-print color="var(--gray-1001)" class="hover-main-red"/>
                                            <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-34px`, left: `-22px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">Imprimir</popup>
                                        </div>
                                        <div v-if="quotation.quote_status_id" class="d-flex cursor-pointer position-relative btn-action ms-2" @click="invoiceQuotation(quotation)" :class="{'btn-cart-disabled ': getState(quotation.quote_status_id).id != 3}">
                                            <icon-invoice :size="13" :color="'var(--gray-1001)'" class="hover-main-red"/>
                                            <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-34px`, left: getState(quotation.quote_status_id).id == 3 ? `-27px` : '-65px', 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                                {{getState(quotation.quote_status_id).id == 3 ? 'Facturar' : `Cotizacion ${getState(quotation.quote_status_id).description}`}}
                                            </popup>
                                        </div>
                                        <div v-if="quotation.return_count" class="d-flex ms-2 cursor-pointer position-relative btn-action" style="top: 2px; right: 9px;" @click="renderInvoiceReturnModal(quotation.order_token_id)">
                                            <icon-devolutions :size="18" :color="'var(--gray-1001)'" class="hover-main-red" />
                                            <popup class="option-tooltip size-13 px-2 py-1" :style="{top: `-34px`, left: `-24px`, 'border-radius': `6px`, 'white-space': 'nowrap'}">
                                                {{$t('Devuelto') }}
                                            </popup>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <template v-if="selectedId == quotation.order_token_id">
                                <tr :key="`collapse-${quotation.order_token_id}`">
                                    <td colspan="12" class="p-4">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="text-start">
                                                <h5 class="weight-600 mb-0">{{$t('account.quotation_details')}}</h5>
                                                <span class="subtitle-14 color-dark-gray-400 weight-300">
                                                    {{$t("product.showing")}} {{search ? result.length : quotation.order_details.length}} {{$t('product.of')}} {{quotation.order_details.length}} {{$t("product.products")}}
                                                </span>
                                            </div>
                                            <div class="d-flex w-75 justify-content-end">
                                                <div class="d-flex align-items-center justify-content-end w-75">
                                                    <main-select :summaryRef="'type-of-search'" :options="searchTypes" :selectedOption="selectedSearchType"
                                                    @clickHandler="(data) => selectedSearchType = data"/>
                                                    <input-search @changeValue="(data)=>searchProducts(data, quotation.order_details)" :value="search" style="max-width: 400px;"/>
                                                </div>
                                                <button class="btn-invoice px-3 d-flex align-items-center ms-3" :disabled="isChanged(quotation.order_token_id)"
                                                @click="invoiceQuotation(quotation)" v-if="quotation.quote_status_id != 'Facturada'">
                                                    <icon-invoice :size="12" class="me-2" color="var(--blue-600)"/>Facturar
                                                </button>
                                            </div>
                                        </div>
                                        <div class="table-response overflow-auto px-1" :style="{maxHeight: `${resize ? 800 : 300}px`}" :id="`table-product-${quotation.order_token_id}`">
                                            <hr class="m-0">
                                            <quotation-product-table :currency="quotation.currency" :products="search ? result : quotation.order_details" :currentState="getState(quotation.quote_status_id)" :quotationID="quotation.order_token_id"
                                            :branchCode="quotation.branch_code" :orderDetails="quotation.order_details" :search="search" @changed="changed = true" @checkResult="checkResult" @resize="resize = !resize"
                                            :resize="resize" @reset="reset(quotation.order_token_id)"/>
                                        </div>
                                        <action-group :quotation="quotation" class="mt-3" :products="search ? result : quotation.order_details" :quotationID="quotation.order_token_id"
                                        v-if="quotation.quote_status_id != 'Facturada'"/>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
                <invoice-return v-if="showInvoiceReturnModal" @closeModal="showInvoiceReturnModal = false" :invoiceReturnData="invoiceReturnData"></invoice-return>
            </div>
        </div>
</template>

<script>
    import iconDevolutions from '../../../home/assets/svg/policies/iconDevolutions.vue'
    import Popup from '../../../../common/components/popup/Popup.vue'
    import iconArrow from '../../../../common/svg/iconArrow.vue'
    import IconCart from '../../../../common/svg/iconCart.vue'
    import IconPrint from '../../../cart/assets/svg/iconPrint.vue'
    import { productMixin } from "@/modules/products/helpers/mixin/product-mixin.js";
    import { quotesMixin } from "../../helpers/mixins/quotes-mixin";
    import { HalfCircleSpinner } from 'epic-spinners'
    import IconCheckCircle from '../../assets/svg/iconCheckCircle.vue'
    import InputSearch from '../../../../common/components/inputs/InputSearch.vue'
    import QuotationProductTable from './QuotationProductTable.vue'
    import ActionGroup from './actions/ActionGroup.vue'
    import { mapGetters } from 'vuex'
    import IconWarning from '../../../../common/svg/iconWarning.vue'
    import { cartMixin } from '../../../cart/helpers/mixins/cart-mixin';
    import MainSelect from '../../../../common/components/selects/MainSelect.vue'
    import IconInvoice from '../../assets/svg/iconInvoice.vue'
    import AlertWarning from '../../../../common/components/alerts/AlertWarning.vue'
    import iconClose from '../../../../common/svg/iconClose.vue';
    import IconCircle from '../../../../common/svg/iconCircle.vue'
    import InvoiceReturn from './modals/InvoiceReturn.vue'

    export default {
        components: { 
            iconArrow, IconCart, IconPrint, Popup, 
            HalfCircleSpinner, IconCheckCircle, 
            QuotationProductTable, IconWarning,
            iconClose,
            iconDevolutions,
            InputSearch, 
            MainSelect,
            IconInvoice,
            ActionGroup,
            AlertWarning,
            IconCircle,
            InvoiceReturn
        },
        name: 'QuotesTable',
        props:{
            quotes:{
                type: Array
            },
            highlightedId: {
                type: String,
                default: ''
            }
        },
        mixins: [productMixin, quotesMixin, cartMixin],
        data(){
            return{
                selectButton: false,
                selectedId: null,
                loading: { active: false, quotation: null},
                showDeleteProductModal: false,
                resize: false,
                search: '',
                result: [],
                changed: false,
                quotationFocus: null,
                cancelChanges:{
                    active: false,
                    id: null
                },
                showInvoiceReturnModal: false,
                searchTypes: [
                    {
                        value: 'code_or_description',
                        title: 'Codigo o descripción'
                    },
                    {
                        value: 'code',
                        title: 'Código'
                    },
                    {
                        value: 'description',
                        title: 'Descripción'
                    },
                    {
                        value: 'quantity_greater_than',
                        title: 'Cantidad mayor a'
                    },
                    {
                        value: 'quantity_less_than',
                        title: 'Cantidad menor a'
                    },
                    {
                        value: 'price_greater_than',
                        title: 'Precio mayor a'
                    },
                    {
                        value: 'price_less_than',
                        title: 'Precio menor a'
                    },
                ],
                selectedSearchType: { value: 'code_or_description', title: 'Codigo o descripción'},
            }
        },
        computed:{
            ...mapGetters({
                user: "auth/userData",
                changedQuotes: "account/changedQuotes",
                employeeActiveCode: 'auth/employeeActiveCode',
                selectedQuotation: "account/selectedQuotation",
                focusQuotation: "account/focusQuotation",                
            })
        },
        methods:{
            showReturnInvoice(documentId){
                this.$store.commit("account/SET_STATE_SELECTED_QUOTATION_FOR_RETURN", documentId)
                this.$store.commit("account/SET_STATE_INVOICE_RETURN_MODAL", true);
            },
            getName(name, type){
                let separate = [...name]
                let find = separate.findIndex(item => item == '/')
                if(find != -1){
                    let name = separate.slice(find + 2, separate.length)
                    let company = separate.slice(0, find)
                    switch (type) {
                        case 'company_name':
                            return company.join('')
                        case 'name':
                            return name.join('')
                        default:
                            break;
                    }
                }else{
                    if(type == 'name')
                    return name
                }
            },
            async print(order) {
                let data = {
                    ...order,
                    order_primary: {},
                    quotation: true
                }
                this.$store.commit('account/SET_INVOICE', data)
                this.$router.push({path: '/account/invoice'})
            },
            async deleteProducts(){
                if(this.isPageDisable){
                    this.$store.commit("common/SET_DISABLE_MODAL", true);
                    return;
                }
                if(this.selectButton){
                    this.deleteSelection = !this.deleteSelection;
                }else if(this.selectedQuotation){
                    try {
                        await this.$store.dispatch('cart/clearCart');
                        this.$store.commit('account/SELECT_QUOTATION', null)
                    } catch (error) {
                        console.log(error)
                    }
                }else{
                    this.showConfirmDelete = true
                }
            },
            searchProducts(value, products){
                this.search = value
                let result = [];
                switch (this.selectedSearchType.value) {
                    case 'code_or_description':
                        result = products.filter(product=> product.product.description.toLowerCase().includes(value.toLowerCase()) ||
                        product.product.codarticulo.toString().includes(value))
                        break;
                    case 'code':
                        result = products.filter(product=>product.product.codarticulo.toString().includes(value))
                        break;
                    case 'description':
                        result = products.filter(product=> product.product.description.toLowerCase().includes(value.toLowerCase()))
                        break;
                    case 'quantity_greater_than':
                        result = products.filter(product=> product.quantity > value)
                        break;
                    case 'quantity_less_than':
                        result = products.filter(product=> product.quantity < value)
                        break;
                    case 'price_greater_than':
                        result = products.filter(product=> product.price_sold > parseFloat(value))
                        break;
                    case 'price_less_than':
                        result = products.filter(product=> product.price_sold < parseFloat(value))
                        break;
                    default:
                        result = products.filter(product=> product.product.description.toLowerCase().includes(value) ||
                        product.product.codarticulo.toString().includes(value))
                        break;
                }
                this.result = result
            },
            checkResult(code){
                if(this.search){
                    const index = this.result.findIndex(product=> product.product.codarticulo == code)
                    this.result.splice(index, 1)
                }
            },
            // disableCloseQuotation(){
            //     if(this.selectedQuotation){
            //         const disableQuote = this.selectedQuotation != id

            //         console.log(this.selectedQuotation)
            //         return
            //     } 
            // },
            isChanged(id){
                let index = this.changedQuotes.findIndex(item=> item.quotation == id)
                return index != -1
            },
            async goToQuotationPosition(id, position){
                if (position) {
                    this.$emit("setSearch", id);
                    await this.$emit("getQuotes");
                    this.$router.push({query: {id: id} }).catch(()=>{});
                    
                    // do not get data from local
                    // const quote = this.quotes.find(quotation => quotation.order_token_id == id)

                    // get quotation from API
                    const quotation = await this.$store.dispatch('account/getQuotationByID', {id: id, refresh: false})

                    // if the selected quotation is invoiced, delete it from the changedQuotes
                    if (quotation.codfactura) {
                        this.$store.commit("account/REMOVE_FROM_CHANGED_QUOTATION", id);
                    }
                }
                this.resize = false
                const quotationDOM = document.getElementById(`quotation-${id}`)
                
                if(this.selectedId == id) 
                    this.selectedId = null
                else {
                    this.selectedId = id
                    if(position)
                    window.scrollTo({top: quotationDOM.getBoundingClientRect().top, behavior:'smooth'});
                }
            },
            async reset(quotationID){
                if(this.search){
                    this.search = ''
                }else{
                    try {
                        await this.$store.dispatch('account/getQuotationByID', {id: quotationID, refresh: true})
                        this.$store.commit('account/REMOVE_FROM_CHANGED_QUOTATION', quotationID)
                    } catch (error) {
                        console.log(error)
                    }
                }
            },
            async resetChangedQuotations() {
                this.$store.commit('account/RESET_CHANGED_QUOTATION');
                await this.$emit("getQuotes");
            },
            async renderInvoiceReturnModal(document_id) {
                const quote = this.quotes.filter(quote => quote.order_token_id == document_id);
                this.invoiceReturnData = quote[0].return;
                this.showInvoiceReturnModal = true;
            }
        },
        mounted(){
            if(this.focusQuotation){
                this.goToQuotationPosition(this.selectedQuotation)
                this.selectedId = this.selectedQuotation
            }
        }
    }
</script>

<style scoped>
    .quotes-table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
        position: sticky;
    }
    .quotes-table > thead > tr > th {
        font-weight: 400;
        font-size: 14px;
    }
    .table::v-deep > :not(:first-child) {
        border-top: 0;
    }

    /* status */
    .status-bg{
        width: fit-content;
        white-space: nowrap; 
        border-radius: 20px;
        font-size: 14px;
    }
    .image__wrapper::v-deep{
        width: 50px;
        height: 50px;
    }
    .option-tooltip {
        border-radius: 6px;
        display: none;
    }
    .popup::v-deep::before {
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(134deg);
        width: 12px;
        height: 12px;
    }
    .btn-action {
        height: 30px;
        width: 30px;
    }
    .btn-action:hover > .option-tooltip, 
    .order-token-id:hover > .option-tooltip{
        display: block;
    }
    .icon-active {
        transform: rotate(-90deg) !important;
    }
    .btn-collapse {
        transform: rotate(90deg);
        transition: var(--transition-1);
    }
    .card-container {
        border-radius: 10px;
    }
    .btn-cart-disabled {
        cursor: no-drop
    }
    .btn-cart-disabled svg{
        fill: var(--gray-100) !important;
        cursor: no-drop;
    }
    .cancel{
        color: #878f96d8;
        background: #66829a14;
    }
    .cancel > svg {
        fill: #878f96d8;
    }
    .invoiced{
        background: #ebf7df;
        color: #89c654;
    }
    .invoiced > svg {
        fill: #89c654;
    }
    .pending{
        background: #fcf7e6;
        color: #e1b000;
    }
    .pending > svg {
        fill: #e1b000;
    }
    .focus-quotation{
        background-color: var(--light-gray-100);
    }
    input:focus {
        box-shadow: none;
    }
    .hithere {
        animation: hithere 1s ease infinite;
    }
    @keyframes hithere {
        30% { transform: scale(1.4); }
        40%, 60% { transform: rotate(-20deg) scale(1.4); }
        50% { transform: rotate(20deg) scale(1.4); }
        70% { transform: rotate(0deg) scale(1.4); }
        100% { transform: scale(1); }
    }
    .changed-quotes:last-child .separator-changed-quotes{
        display: none;
    }

    @media (max-width: 476px) {
        .quotes-table > tbody >  tr {
            white-space: nowrap;
        }
    }
    .select-container{
        width: 200px;
        height: 35px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .input-search::v-deep > input {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
    .amount-changed{
        text-decoration: line-through;
    }
    .btn-invoice{
        background-color: transparent;
        border: 1.5px solid var(--blue-600);
        color: var(--blue-600);
        height: 35px;
        border-radius: 7px;
        font-weight: bold;
    }
    .btn-invoice:disabled{
        border-color: var(--gray-1001) !important;
        cursor: no-drop;
        color: var(--gray-1001) !important
    }
    .btn-invoice:disabled > svg{
        fill:  var(--gray-1001);
    }
</style>