<template>
    <div class="tabs position-relative d-flex p-0">
        <template v-for="(option, index) in options">
            <!-- <span :key="index">{{option.value + index}}</span> -->
            <div class="tab d-flex align-items-center p-2" :class="{active: selected == option.value}" 
            @click="$emit('selectTab', option.value)" :key="option.value" v-if="index < max">
                {{option.text}}
                <icon-warning :size="15" class="ms-2 hithere" v-if="isChanged(option.value)"/> 
            </div>
        </template>
        <template v-if="options.length > max">
            <a class="btn-more ms-auto d-flex align-items-center" @click.prevent="showMore = !showMore">
                +{{options.length - max}}
            </a>
        </template>
        <popup :style="{top: `40px`, right: 0}" class="p-2 rounded" :arrowPosition="'right-top-arrow'"
            v-if="showMore">
            <h4 class="mb-0">Cotizaciones</h4>
            <ul class="list-unstyled mb-0">
                <li v-for="option in options.slice(max)" :key="`more-${option.value}`">
                    <a href="#" @click.prevent="$emit('selectTab', option.value)">
                        <icon-warning :size="15" class="me-1 hithere" v-if="isChanged(option.value)"/> {{option.value}}
                    </a>
                </li>
            </ul>
        </popup>
    </div>
</template>

<script>
    import iconWarning from '../../svg/iconWarning.vue'
    import Popup from '../popup/Popup.vue'
    export default {
    components: { iconWarning, Popup },
        name: 'SlidingTab',
        props:{
            options: {
                type: Array
            },
            selected:{
                type: String
            },
            changeOptions:{
                type: Array
            },
            max:{
                type: Number
            }
        },
        data(){
            return{
                showMore: false
            }
        },
        methods:{
            isChanged(id){
                let index = this.changeOptions.findIndex(item => item.quotation == id)
                return index != -1
            },
        }
    }
</script>

<style scoped>
    .tab{
        margin-left: 10px;
        cursor: pointer;
    }
    .tab:first-child{
        margin-left: 0;
    }
    .tab.active{
        background-color: #bc2c350d;
        color: var(--main-red);

        border-radius: 10px;

    }
</style>