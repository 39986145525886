<template>
    <icon-base :viewBox="'0 0 100 100'" :fill="color" :size="size">
        <g id="fotos">
            <path d="M87.402,3.406H50.001H12.598c-6.907,0-12.506,5.599-12.506,12.505v48.564c0,6.906,5.599,12.505,12.506,12.505
                h7.646v17.991c0,1.442,1.743,2.165,2.763,1.145l25.8-19.136h38.596c6.906,0,12.506-5.599,12.506-12.505V15.911
                C99.908,9.005,94.308,3.406,87.402,3.406z M78.724,52.352H21.276c-1.419,0-2.568-1.15-2.568-2.568s1.149-2.568,2.568-2.568h57.449
                c1.419,0,2.569,1.15,2.569,2.568C81.293,51.202,80.143,52.352,78.724,52.352z M78.724,33.394H21.276
                c-1.419,0-2.568-1.15-2.568-2.568c0-1.419,1.149-2.568,2.568-2.568h57.449c1.419,0,2.569,1.15,2.569,2.568
                C81.293,32.244,80.143,33.394,78.724,33.394z"/>
        </g>
    </icon-base>
</template>

<script>
export default {
    name:'iconComment',
    components:{
        IconBase: () => import('@/common/components/iconBase.vue')
    },
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: 'var(--main-navy)'
        },
        enableBackground: {
            type: Boolean
        }
    }
}
</script>
