<template>
    <div :id="`quotation-${quotation.order_token_id}`">
        <div class="quotation-header justify-content-between d-flex align-items-center mb-3">
            <div class="d-flex flex-column align-items-start">
                <h6 class="size-14 mb-1 position-relative d-flex align-items-center justify-content-center order-token-id" 
                :class="[{'color-main-red': selectedQuotation == quotation.order_token_id}, {'color-blue-600': isChanged(quotation.order_token_id)}]">
                    <icon-check-circle :size="14" class="me-2" v-if="selectedQuotation == quotation.order_token_id"/> 
                    <icon-warning :size="14" class="me-2 hithere" v-if="isChanged(quotation.order_token_id)"/>
                    {{quotation.order_token_id}}
                </h6>
                <span class="color-dark-gray-200">{{quotation.number_of_items}} {{$t('account.product') }}</span>
            </div>
            <div>
                <h6 class="size-14 mb-1">{{moment(quotation.creation).lang($i18n.locale).fromNow(true) | textFormat}} 
                    <span class="color-dark-gray-200">{{moment(quotation.creation).format('LTS')}}</span>                    
                </h6>
                <span class="color-dark-gray-200">{{ $d(new Date(quotation.creation), 'alt_short')  | textFormat}}</span>
            </div>
            <div>
                <span class="color-dark-gray-200 weight-800">(<span class="color-main-navy weight-500">{{quotation.customer_code}}</span>) </span>
                {{getName(quotation.customer_name, 'name') | textFormat}}
            </div>
            <div>
                <div class="d-flex align-items-center">
                    {{quotation.place.description | textFormat}}
                    <icon-branch :size="16" color="var(--blue-600)" class="ms-2" v-if="false"/>
                    <div class="status-bg px-2 ms-2" :class="state.value">{{quotation.quote_status_id}}</div>
                </div>
                <span class="subtitle-14 color-dark-gray-300 text-capitalize">{{ quotation.place.city_name + ', ' + quotation.place.address | textFormat}}</span>
            </div>
            <div>
                <h6 class="mb-0">{{quotation.currency.description}}</h6> 
                <span class="color-main-red weight-600 size-18" :class="{'amount-changed': isChanged(quotation.order_token_id)}">{{quotation.amount | moneyFormat}}</span>
            </div>
        </div>
        <div class="table-response overflow-auto" style="max-height: 62vh;">
            <quotation-product-table :products="search ? result : quotation.order_details" :currentState="state" :quotationID="quotation.order_token_id"
            :branchCode="quotation.branch_code" :orderDetails="quotation.order_details" @changed="changed = true" @checkResult="checkResult"/>
        </div>
        <div class="d-flex justify-content-end size-14">
            <a href="" @click.prevent="moveToCart(quotation)">{{$t('account.add_card')}}</a>
            <span class="mx-2 color-gray-1001">|</span>
            <a href="" @click.prevent="print(quotation)">{{$t('account.print')}}</a>
            <span class="mx-2 color-gray-1001">|</span>
            <a href="" @click.prevent="invoiceQuotation(quotation)">{{$t('account.invoice')}}</a>
        </div>
        <action-group :quotation="quotation" class="mt-3" :products="search ? result : quotation.order_details" :quotationID="quotation.order_token_id"/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import IconBranch from '../../../../common/svg/iconBranch.vue'
    import IconWarning from '../../../../common/svg/iconWarning.vue'
    import IconCheckCircle from '../../assets/svg/iconCheckCircle.vue'
    import QuotationProductTable from './QuotationProductTable.vue'
    import ActionGroup from './actions/ActionGroup.vue'
    import { quotesMixin } from "../../helpers/mixins/quotes-mixin";
    import { cartMixin } from '../../../cart/helpers/mixins/cart-mixin';

    export default {
        components: { QuotationProductTable, IconBranch, IconCheckCircle, IconWarning, ActionGroup },
        name: 'QuotationDetails',
        props:{
            quotation:{
                type: Object
            }
        },
        mixins: [quotesMixin, cartMixin],
        computed:{
            ...mapGetters({
                selectedQuotation: "account/selectedQuotation",
                changedQuotes: "account/changedQuotes",
            }),
            state(){
                const status = [
                    {id: 1, description: 'Anulada', value: 'cancel'},
                    {id: 2, description: 'Facturada', value: 'invoiced'},
                    {id: 3, description: 'Pendiente', value: 'pending'},
                ]
                return status.find(state=>state.description == this.quotation.quote_status_id)
            }
        },
        data(){
            return {
                search: '',
                loading: { active: false, quotation: null},
                saving: false
            }
        },
        methods:{
            checkResult(code){
                if(this.search){
                    const index = this.result.findIndex(product=> product.product.codarticulo == code)
                    this.result.splice(index, 1)
                }
            },
            isChanged(id){
                let index = this.changedQuotes.findIndex(item=> item.quotation == id)
                return index != -1
            },
            getName(name, type){
                let separate = [...name]
                let find = separate.findIndex(item => item == '/')
                if(find != -1){
                    let name = separate.slice(find + 2, separate.length)
                    let company = separate.slice(0, find)
                    switch (type) {
                        case 'company_name':
                            return company.join('')
                        case 'name':
                            return name.join('')
                        default:
                            break;
                    }
                }else{
                    if(type == 'name')
                    return name
                }
            },
            async print(order) {
                let data = {
                    ...order,
                    order_primary: {},
                    quotation: true
                }
                
                this.$store.commit('account/SET_INVOICE', data)
                this.$router.push({path: '/account/invoice'})
            },
        }
    }
</script>

<style scoped>
    /* status */
    .status-bg{
        width: fit-content;
        white-space: nowrap; 
        border-radius: 20px;
        font-size: 14px;
    }
    .cancel{
        color: #878f96d8;
        background: #66829a14;
    }
    .invoiced{
        background: #ebf7df;
        color: #89c654;
    }
    .pending{
        background: #faebb9;
        color: #d8a10a;
    }
    .amount-changed{
        text-decoration: line-through;
    }
</style>