
<template>
    <div class="row mt-4 mb-4">
        <div class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center mb-4 px-2" @click="goBack">
            <div class="me-3 mt-2 mt-md-0">
                <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'"/>
            </div>
            <div class="d-flex flex-column align-items-start ms-1">
                <h6 class="title-22 color-blue-700 mb-1">{{$t("account.quotes")}}</h6>
                <h6 class="subtitle-16 mb-0">{{$t("account.description_my_quotes")}}</h6>
            </div>
        </div>
        <div class="col-md-5 d-flex align-end mb-2 mb-sm-4 justify-content-center justify-content-md-end align-items-end">
            <!-- <input-search :placeholder="'account.search_by_id'" class="mb-0" style="max-width: 350px;" @search="getQuotes" @keyupEnter="getQuotes"
            @changeValue="(data)=>{search = data}"/> -->
            <input-search :placeholder="'account.search_by_id'" class="mb-0" style="max-width: 350px;" @search="getQuotes" @keyupEnter="getQuotes"
            @changeValue="(data)=>{search = data}" :value="search"/>
        </div>
        <div class="d-flex justify-content-end">
                <label class="mb-2 subtitle-15"  id="credit">{{$t("checkout.your_credit_limit_balance")}}:
                    <span class="text-danger weight-600">{{user.customer.balance | moneyFormat }}</span>
                </label>
        </div>
        <div class="col-md-12 mb-2">
            <hr class="mt-1">
        </div>
        <div class="col-md-12">
            <div class="row">
                <div class="col-xl-3">
                    <span class="weight-500 size-14 color-dark-gray-400 text-nowrap">{{$t("account.range")}}</span>
                    <date-picker v-model="filters.range" valueType="format" placeholder="1993-29-05" class="w-100" range/>
                </div>
                <div class="col-xl-2">
                    <span class="weight-500 size-14 color-dark-gray-400 text-limited">{{$t("account.orders_placed_in")}} </span>
                    <main-select class="ms-auto ms-md-0" :options="filterBranches" :selectedOption="selectedBranch" @clickHandler="selectBranch"
                    :summaryRef="'branch'"/>
                </div>
                <div class="col-xl-2">
                    <span class="weight-500 size-14 color-dark-gray-400">{{$t("account.show")}} </span>
                    <main-select class="ms-auto ms-md-0" :options="time" :selectedOption="filterDate"
                    :summaryRef="'time'" @clickHandler="(data) => {filterDate = data}"/>
                </div>
                <div class="col-xl-2">
                    <span class="weight-500 size-14 color-dark-gray-400 text-limited">{{$t("account.filter_state")}} </span>
                    <main-select class="ms-auto ms-md-0" :options="status" :selectedOption="filters.state" @clickHandler="(data)=>{filters.state.value = data.value}"
                    :summaryRef="'branch'"/>
                </div>
                <div class="col-xl-3 d-flex align-items-end justify-content-between">
                    <main-switch :id="'change-layout'" description="Tabs" :size="10" :value="layoutTabs" @changeValue="(data) => {layoutTabs = data}" class="me-2 pb-1"/>
                    <button class="d-flex border align-items-center btn-refresh" @click="getQuotes">
                        <icon-refresh :size="16" :class="{active: loading}" class="icon-refresh"/>
                        <span class="mb-0 ms-1 color-blue-600 cursor-pointer title-14">Refresh</span>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="layoutTabs">
            <hr class="mt-3">
            <sliding-tab class="mt-3" :options="tabs" :selected="selectedQuotationTab" @selectTab="(data)=>{selectedQuotationTab = data}"
                :changeOptions="changedQuotes" :max="maxTabsDisplayed"/>
            <hr class="mt-3">
            <div class="d-flex justify-content-end mb-2 align-items-center" v-if="changedQuotes.length > 0">
                <icon-warning class="me-2 hithere" :size="14"/>
                <span class="color-main-red size-14">Cambios sin guardar en
                    <a href="#" v-for="item in changedQuotes.map(quotation=> quotation.quotation)" :key="item" class="changed-quotes" @click.prevent="selectedQuotationTab = item">
                        {{item}}
                        <span class="separator-changed-quotes">,</span>
                    </a>
                </span>
                <hr>
            </div>
            <quotation-details :quotation="selectedQuotationByTab" class="p-0"/>
        </div>
        <quotes-table :quotes="quotes.data" :highlightedId="highlightedId" @getQuotes="getQuotes" @setSearch="setSearch" class="mt-4" v-else-if="quotes.data.length > 0 && !loading"/>
        <quotation-summary class="mt-4" v-if="false"/>
        <without-results v-if="!quotes.data.length && !loading" @click="resetFilters" class="mt-5" description="account.no_item_matches" 
            title="account.no_quotes_found" titleButton="account.reset_filters">
            <template #main-icon>
                <div>
                    <icon-search-order :size="90"/>
                </div>
            </template>
            <template #button-icon>
                <icon-change :size="16" class="fill-white me-2" style="margin-top: -2px;"/>
            </template>
        </without-results>
        <lottie :options="defaultOptions" :height="250" :width="250" v-if="loading"/>
        <main-modal v-if="false"><invoice/></main-modal>
        <main-pagination v-if="quotes.data.length > 0" :total="quotes.last_page" :current="filters.page" @setPage="setPage" class="mt-5" />
    </div>
</template>
<script>
import Lottie from "vue-lottie";
import animationData from '../assets/animations/searching1.json';
import { mapGetters } from "vuex"
import InputSearch from '../../../common/components/inputs/InputSearch.vue'
import MainSelect from '../../../common/components/selects/MainSelect.vue'
import IconSearchOrder from '../assets/svg/iconSearchOrder.vue'
import IconArrow from '../../../common/svg/iconArrow.vue'
import IconChange from '../../products/assets/svg/iconChange.vue';
import WithoutResults from '../../../common/components/modals/WithoutResults.vue';
import QuotesTable from '../components/quotes/QuotesTable.vue';
import QuotationSummary from '../components/quotes/QuotationSummary.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import MainModal from '../../../common/components/modals/MainModal.vue';
import Invoice from './Invoice.vue';
import SlidingTab from '../../../common/components/tabs/SlidingTab.vue';
import QuotationDetails from '../components/quotes/QuotationDetails.vue';
import MainSwitch from '../../../common/components/switch/MainSwitch.vue';
import IconWarning from '../../../common/svg/iconWarning.vue';
import MainPagination from '../../../common/components/pagination/MainPagination.vue';
import IconRefresh from '../../../common/svg/iconRefresh.vue';
import { toPascalCase } from '@/common/helpers/global-methods.js'


export default {
    name:"Quotes",
    components:{
        InputSearch,
        MainSelect,
        // CardOrder,   
        IconSearchOrder,
        IconArrow,
        // CardOrderMobile,
        Lottie,
        IconChange,
        WithoutResults,
        QuotesTable,
        QuotationSummary,
        DatePicker,
        MainModal,
        Invoice,
        SlidingTab,
        QuotationDetails,
        MainSwitch,
        IconWarning,
        MainPagination,
        IconRefresh
    },
    data(){
        return {
            selectButton: false,
            selectedOption: {value: 'ongoingOrders', title: 'account.ongoing_orders'},
            selectedBranch: {value: 0},
            time:[
                {value: this.moment().subtract(10, 'days').format('YYYY-MM-DD'), title:'account.last_10_days'},
                {value: this.moment().subtract(6, 'month').format('YYYY-MM-DD'), title:'account.last_6_months'},
                {value: this.moment().subtract(1, 'year').format('YYYY-MM-DD'), title:`${this.nowDate() - 1}`},
                {value: this.moment().subtract(2, 'year').format('YYYY-MM-DD'), title:`${this.nowDate() - 2}`}
            ],
            search: '',
            filterDate: {
                title: 'account.last_10_days',
                value: this.moment().subtract(10, 'days').format('YYYY-MM-DD'), 
            },
            loading: false,
            defaultOptions: { animationData: animationData, loop: true },
            resetValue: false,
            filters:{
                range: [this.moment().subtract(10, 'days').format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')],
                page: 1,
                state: {value: '', title: 'account.all'}
            },
            selectedQuotationTab: null,
            layoutTabs: false,
            status: [
                {value: '', title: 'account.all'},
                {value: 'Pendiente', title: 'account.pending'},
                {value: 'Facturada', title: 'account.billed'},
                {value: 'Anulada', title: 'account.canceled'},
            ],
            highlightedId: ''
        }
    },
    computed:{
        ...mapGetters({
            branches: "branches/branches",
            branchSelected: "branches/branchSelected",
            quotes: "account/quotes",
            user: "auth/userData",
            changedQuotes: "account/changedQuotes",
            openInvoiceReturnModal: false
        }),
        filterBranches(){
            const newObject = [];
            const options = this.branches;
            newObject.push({ value: 0, title: 'account.all_branches' });
            for(let option of options) {
                newObject.push( {
                    value: option.codplace,
                    title: this.$options.filters.textFormat(option.description)
                });
            }
            return newObject
        },
        tabs(){
            let options = this.quotes.data.map(quotation=> {return {value: quotation.order_token_id, text: quotation.order_token_id}})
            return options
        },
        selectedQuotationByTab(){
            let quote = this.quotes.data.find(quotation=> quotation.order_token_id == this.selectedQuotationTab)
            return quote
        },
        maxTabsDisplayed(){
            switch (this.viewPort()) {
                case 'xxl':
                    return 10
                case 'xl':
                    return 9
                case 'lg':
                    return 7
                case 'md':
                    return 5
                case 'sm':
                    return 4
                default:
                return null
            }
        }
    },
    watch:{
        async 'user.username'(val){
            if(val)
            await this.getQuotes()
        },
        layoutTabs(val){
            if(val){
                if(!this.selectedQuotationTab)
                this.selectedQuotationTab = this.quotes.data[0].order_token_id
            }
        },
        filters:{
            deep: true,
            async handler(){
                await this.getQuotes()
            }
        },
        filterDate(val){
            this.filters.range = [val.value, this.moment().format('YYYY-MM-DD')]
        }
    },
    methods:{
        nowDate(){
            const date = new Date().getFullYear();
            return date
        },
        setSearch(value) {
            this.search = value;
        },
        goBack(){
            this.$router.go(-1)
        },
        async getQuotes(){
            try {
                this.loading = true
                
                let params;
                if (!this.search) {
                    params = {
                        ...(this.search && {q: this.search}),
                        page: this.filters.page,
                        branch_code: this.selectedBranch.value ? this.selectedBranch.value : null,
                        start_date: this.filters.range[0],
                        end_date: this.filters.range[1],
                        status: this.filters.state.value,
                        include_returns: 1
                    }
                } else {
                    // if the search filter is empty, filter only by document_id and ignore the others filters
                    params = {
                        ...(this.search && {q: this.search}),
                    }
                }
                await this.$store.dispatch('account/getQuotes', params)
                setTimeout(() => {
                    this.$store.commit('account/SET_STATE_FOCUS_QUOTATION', false)
                }, 1000);
            } catch (error) {
                console.log(error)
                this.$store.commit('common/SHOW_MODAL_ERROR', {
                    show: true, 
                    title: `Ocurrio un error obteniendo los datos.`, 
                    description: error.response.data.message
                })
            } finally{
                this.loading = false        
            }
        },
        resetFilters(){
            this.filterDate = {
                title: 'account.last_10_days',
                value: this.moment().subtract(10, 'days').format('YYYY-MM-DD'), 
            }
            this.filters = {
                range: [this.moment().subtract(10, 'days').format('YYYY-MM-DD'), this.moment().format('YYYY-MM-DD')],
                page: 1,
                state: {value: 'Pendiente'}
            }

            this.search = ""
            this.$router.push({}).catch(()=>{});
        },
        setPage(page){
            this.filters.page = page
        },
        async selectBranch(data){
            this.selectedBranch = data
            await this.getQuotes()
        }
    },
    async created(){
        if (this.$route.query.q) {
            this.$store.commit('account/SET_STATE_FOCUS_QUOTATION', true)
            this.search = this.$route.query.q;
        }
        
        try {
            let queryState = toPascalCase(this.$route.query?.state);
            let queryId = this.$route.query?.q;

            if (queryState) {
                const state = this.status.find(state => state.value == queryState)
                if (state) {
                    this.filters.state = { value: state.value }
                }
            }

            if (queryId) {
                this.search = queryId;
                this.highlightedId = queryId
            }
            await this.getQuotes()
        } catch (error) {
            console.log(error);
        }
    }
}
</script>


<style scoped>
    .nav::v-deep > .nav-item > .nav-link {
        margin-right: 35px !important;
    }
    .select-container::v-deep > div > span {
        font-size: 13px !important;
    }
    .hover-blue svg {
        transition: var(--transition-1);
    }
    .hover-blue:hover svg{
        fill: var(--blue-600);
    }
    .nav-container {
        margin-bottom: 15px;    
    }
    .select-container {
        min-width: 190px !important;
        height: 32px !important;
    }

    @media (min-width : 768px) {
        .nav-container {
            margin-bottom: 20px;    
        }
        .card-order {
            margin-top: 45px;
        }
        .select-container {
            min-width: 170px !important;
        }
    }
    @media (min-width : 992px) {
        .nav-container {
            margin-bottom: 30px;    
        }
        .card-order {
            margin-top: 45px;
        }
        .time-filter-container {
            max-width: 325px;
        }
    }
    @media (min-width : 1200px) {
        .time-filter-container {
            max-width: 305px;
        }
        .card-order {
            margin-top: 50px;
        }
    }
    @media (min-width : 1400px) {
        .time-filter-container {
            max-width: 325px;
        }
    }
    .icon-refresh.active{
        animation: loading 1s infinite ease-in-out;
    }
    @keyframes loading {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input {
        border-color: var(--gray-50);
        color: var(--main-navy);
        border-radius: 5px;
        height: 32px !important;
        padding: 0px;
        padding-left: 32px;
        box-shadow: none;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > input::placeholder{
        color: var(--dark-gray-200);;
    }
    .mx-datepicker::v-deep > .mx-input-wrapper > .mx-icon-calendar{
        left: 10px;
    }
    .card-container{
        animation: none;
    }
    .changed-quotes:last-child .separator-changed-quotes{
        display: none;
    }
    .main-switch::v-deep > span{
        color: var(--dark-gray-200);
    }
    .btn-refresh{
        border-radius: 20px;
        height: 32px;
        padding: 10px 14px;
        background-color: transparent;
    }
    .btn-refresh:hover{
        border-color: var(--blue-600) !important;
    }
</style>
