<template>
<div class="d-flex flex-column justify-content-center py-2">
    <main-modal @closeModal="$emit('closeModal')">
        <div class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center mb-4 px-2">
            <div class="d-flex flex-column align-items-start ms-1">
                <h3 class="title-22 color-blue-700 mb-1">{{$t("account.devolutions_invoice")}}</h3>
            </div>
        </div>
        <table class="table invoice-return-table m-0 align-middle">
            <thead class="text-center align-middle">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{{$t('account.invoice_return')}}</th>
                    <th scope="col">{{$t('account.invoice_number')}}</th>
                    <th scope="col">{{$t('account.amount')}}</th>
                    <th scope="col">{{$t('account.employee')}}</th>
                    <th scope="col">{{$t('account.location')}}</th>
                    <th scope="col">{{$t('account.commentary')}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(devolution, index) in invoiceReturnData" :key = devolution.coddsv >
                    <td>{{ index + 1 }}</td>
                    <td>{{devolution.coddsv}}</td>
                    <td>{{devolution.codfactura }}</td>
                    <td class="color-main-red weight-600">{{devolution.total | moneyFormat}}</td>
                    <td>{{devolution.codempleado2 }}</td>
                    <td>{{devolution.codlocalidad }}</td>
                    <td>{{devolution.comentario }}</td>
                </tr>
            </tbody>
        </table>
    </main-modal>
</div>
</template>

<script>

import MainModal from '../../../../../common/components/modals/MainModal.vue';
import { mapGetters } from 'vuex';


export default {
    components: {
        MainModal
    },
    props:{
        invoiceReturnData: {
            type: Array
        },
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            quotes: "account/quotes",
            user: "auth/userData",
        }),
    },
    name: "InvoiceReturn",
    methods:{
        closeModal(){
            this.$store.commit("account/SET_STATE_INVOICE_RETURN_MODAL", false);
        }
    }
}
</script>

<style scoped>
    .invoice-return-table > thead {
        background-color: var(--light-gray-100);
        color: var(--dark-gray-200);
        position: sticky;
    }
    .invoice-return-table > thead > tr > th {
        font-weight: 400;
        font-size: 14px;
    }
    @keyframes hithere {
        30% { transform: scale(1.4); }
        40%, 60% { transform: rotate(-20deg) scale(1.4); }
        50% { transform: rotate(20deg) scale(1.4); }
        70% { transform: rotate(0deg) scale(1.4); }
        100% { transform: scale(1); }
    }
    .changed-quotes:last-child .separator-changed-quotes{
        display: none;
    }

    @media (max-width: 476px) {
        .invoice-return-table > tbody >  tr {
            white-space: nowrap;
        }
    }
</style>